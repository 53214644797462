
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    txt: {
      type: String,
      default: '暂无数据'
    },
    height: String
  },
  setup() {
    const data = reactive({
      uploadRef: null
    })

    return {
      ...toRefs(data)
    }
  }
})
