
import { getFileUrls } from '@/api/common'
import { toResult } from '@/utils/toResult'
import { defineComponent, reactive, toRefs, watch } from 'vue'
import CNodata from '@/components/c-nodata/index.vue'
export default defineComponent({
  components: { CNodata },
  name: 'CImgView',
  props: {
    images: {
      type: Array
    },
    modelValue: String, // 默认不开启， 开启 图片在组件中获取
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    noImage: {
      type: Boolean, // 没有图片不展示未上传图片
      default: false
    },
    txt: {
      type: String, // 未上传图片描述
      default: '未上传图片'
    },
    filenames: String, // 传入的是filenames
    isPdf: Boolean // 是否是pdf需要下载
  },
  setup(props) {
    const state = reactive({
      isViewer: false,
      srcList: [],
      currentImages: []
    })

    // 关闭图片预览
    const handleViewerClose = (): void => {
      state.isViewer = !state.isViewer
    }

    // 点击图片
    const clickImg = async () => {
      if (props.filenames) {
        const imgsList = props.filenames.split(',')
        const [err, res] = await toResult(getFileUrls({ filenames: imgsList }))
        if (err) return
        if (props.isPdf) {
          window.open(res[0].url) // 查看下载pdf类型的文件
        } else {
          state.isViewer = !state.isViewer
          state.currentImages = res.map(item => item.url) // 查看图片
        }
      } else {
        state.isViewer = !state.isViewer
        state.currentImages = props.images
      }
    }

    // 查看图片
    const openImg = item => {
      state.isViewer = !state.isViewer
      const index = state.srcList.indexOf(item)
      const tempImgList = [...state.srcList] // 所有图片地址
      // 调整图片顺序，把当前图片放在第一位
      const start = tempImgList.splice(index)
      const remain = tempImgList.splice(0, index)
      state.currentImages = start.concat(remain) // 将当前图片调整成点击缩略图的那张图片
    }

    watch(
      () => props.images,
      newVal => {
        state.currentImages = newVal
      }
    )

    // 获取图片
    const getImgUrls = async filename => {
      const imgsList = filename.split(',')
      const [err, res] = await toResult(getFileUrls({ filenames: imgsList }))
      if (err) return
      state.srcList = res.map(item => item.url)
    }

    watch(
      () => props.modelValue,
      n => {
        if (n) {
          if (n.indexOf('http') === -1) {
            getImgUrls(n)
          } else {
            state.srcList = props.modelValue.split(',').map(item => item)
          }
        }
      },
      {
        immediate: true
      }
    )
    return {
      ...toRefs(state),
      handleViewerClose,
      clickImg,
      openImg
    }
  }
})
